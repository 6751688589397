
import feather from 'feather-icons';
export default {
  data() {
    return {
      icon: 'send',
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
