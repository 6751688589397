
import VueSlickCarousel from 'vue-slick-carousel';
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      portfolioContent: [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FWeb%20Develpment%20.png?alt=media&token=c75d51a8-e459-4d72-98c7-f730f73e11f9',
          title: 'Web Development',
          id: 'websites',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FMobile%20Development%20.png?alt=media&token=293c347b-9063-40dc-8763-85350e50136b',
          title: 'Mobile Apps',
          id: 'mobile',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2Fsystem%20Development%20.png?alt=media&token=616a3ef4-5fc5-40d1-8993-48e5096a7f42',
          title: 'System',
          id: 'system',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FBranding%20.png?alt=media&token=aa1cddcb-ca84-4066-8004-301570b55b4a',
          title: 'Branding',
          id: 'branding',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FCase%20Study%20.png?alt=media&token=5ac66232-e4a9-46f4-8b71-9fda8240fee8',
          title: 'Case Study',
          id: 'case-study',
        },
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FUi%20UX.png?alt=media&token=88b14bfc-81d7-44ce-9d1f-e6af31669cff',
          title: 'UI/UX',
          id: 'ui-ux',
        },
         {
          src: 'https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/portfolio%2Fcategories%2FDigital%20Marketing%20.png?alt=media&token=f11d82e2-05e3-492e-8cb2-4b85f73e8495',
          title: 'Digital Marketing',
          id: 'digital-marketing',
        },
      ],
      // for all works
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        spaceBetween: 15,

        responsive: [
          {
            breakpoint: 890,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 490,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
};
