
import { ValidationObserver } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import VuePhoneNumberInput from 'vue-phone-number-input';
import countries from '../../../assets/countries.js';
import { mapActions } from 'vuex';
import { FileUploader } from '@/shared/firebase/file-uploader.js';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        country: '',
        company: '',
        nda: false,
        phone: null,
        attachments: null,
      },
      snackbar: false,
      timeout: 3000,
      path: 'contact-form',
      loading: false,
      countriesDropDown: countries.map(
        (a) => a.countryName,
      ),
    };
  },
  methods: {
    async onSubmit() {
      console.log(this.formData);
      this.loading = true;
      var uploads = [];
      if (this.formData.attachments) {
        for (const file of this.formData.attachments) {
          uploads.push(
            await FileUploader.uploadFile(this.path, file),
          );
        }
      }
      await this.doSubmitContactForm({
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        phoneNumber: this.formData.phone,
        message: this.formData.message,
        country: this.formData.country,
        company: this.formData.company,
        nda: this.formData.nda,
        attachments: uploads,
      });
      this.loading = false;
      this.clearForm();
      this.snackbar = true;
    },
    clearForm() {
      this.formData.firstName = '';
      this.formData.lastName = '';
      this.formData.email = '';
      this.formData.phoneNumber = '';
      this.formData.message = '';
      this.formData.country = '';
      this.formData.company = '';
      this.formData.nda = false;
      this.formData.attachments = null;
    },
    getInput(payload) {
      this.formData.phone = payload.formattedNumber;
    },
    ...mapActions({
      doSubmitContactForm: 'contact/doSubmitContactForm',
    }),
    //   filterCountries() {
    //     return countries.cou== "22-02-2016";
    // }
  },
};
