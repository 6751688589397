
import feather from 'feather-icons';
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: 'layers',
          title: 'Website Development',
          desc: `Web Development aims to offer custom services for front-end and back-end, 
including web application development.`,
        },

        {
          icon: 'smartphone',
          title: 'Mobile Development',
          desc: `From prototype to production, we lead the entire app development process 
as we specialize in creating engaging and highly functional mobile 
applications for a variety of industries.`,
        },
        {
          icon: 'monitor',
          title: 'Game Development',
          desc: `Making your game a dream comes true with our customized full-cycle 
development services by leading your project through all stages from game 
design to post-release support, using the extensive experience and creativity 
of our experts.`,
        },
        {
          icon: 'layout',
          title: 'UI/UX Design',
          desc: `Initiate your software product development with a clear design process of UX 
and UI to get a clear visual for recent and further stages.`,
        },
        {
          icon: 'camera',
          title: 'Branding and Photography',
          desc: `Helping you show off who you are and what you’re about, so you can better 
connect with potential customers`,
        },
        {
          icon: 'users',
          title: 'Digital Marketing',
          desc: `We are going to help you providing digital solutions that are both agile and 
efficient including Digital Productions, Social Media Creative Content, 
Management and Media Buying.`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
