
import Globe from 'globe.gl';
const MAP_CENTER = { lat: 30.0499603465, lng: 31.2499682197, altitude: screen.width<500 ? 4  : 1.5};
export default {
  components: {},
  data() {
    return {
      isDark: this.$vuetify.theme.dark
    };
  },

  methods: {},
  computed: {
     earthGlobe(){
       return '//unpkg.com/three-globe/example/img/earth-water.png'
     },
    //  globeBackground(){
    //    return this.$vuetify.theme.dark ?  '//unpkg.com/three-globe/example/img/night-sky.png' : 'https://lh3.googleusercontent.com/proxy/rjCQHlzC34pvivUb9qeiCFqTsAVqy58aa9KsqZsBnqFJ_hGOIP-t09PBaQnhGmm0aSkdAkcX5NOJowmNd7TlFCDGe-0QWWEoGjz3aYBgmgx96DCuWvn9PYDNBj7s-UL2x4imLhvtz9VKQ8MQwQ'
    //  } 
  },
  watch:{
    // '$vuetify.theme.dark'(newvalue){
    //   this.isDark = newvalue
    //   let earthGlobe= '//unpkg.com/three-globe/example/img/earth-water.png'
    //   // let globeBackground= newvalue ?  '//unpkg.com/three-globe/example/img/night-sky.png' : 'https://lh3.googleusercontent.com/proxy/rjCQHlzC34pvivUb9qeiCFqTsAVqy58aa9KsqZsBnqFJ_hGOIP-t09PBaQnhGmm0aSkdAkcX5NOJowmNd7TlFCDGe-0QWWEoGjz3aYBgmgx96DCuWvn9PYDNBj7s-UL2x4imLhvtz9VKQ8MQwQ'

    //       fetch('globe-cities.geojson')
    //   .then((res) => res.json())
    //   .then((places) => {
    //     Globe()
    //       .pointOfView(MAP_CENTER,100)
    //       .globeImageUrl(
    //         earthGlobe
    //       )
    //       // .backgroundImageUrl(
    //       //   globeBackground,
    //       // )
    //       .backgroundColor('rgba(0, 0, 0, 0)')
    //       .labelsData(places.features)
    //       .labelLat((d) => d.properties.latitude)
    //       .labelLng((d) => d.properties.longitude)
    //       .labelText((d) => d.properties.name)
    //       .labelSize(
    //         (d) => Math.sqrt(d.properties.pop_max) * 4e-4,
    //       )
    //       .labelDotRadius(
    //         (d) => Math.sqrt(d.properties.pop_max) * 4e-4,
    //       )
    //       .labelColor(() => 'rgba(255, 165, 0, 0.75)')
    //       .labelResolution(2)(
    //       document.getElementById('globeViz'),
    //     );
    //   });

    // }
  },
  created() {
              fetch('globe-cities.geojson')
      .then((res) => res.json())
      .then((places) => {
        Globe()
          .pointOfView(MAP_CENTER,300)
          .globeImageUrl(
            this.earthGlobe
          )
          // .backgroundImageUrl(
          //   this.globeBackground,
          // )
          .backgroundColor('rgba(0, 0, 0, 0)')
          .labelsData(places.features)
          .labelLat((d) => d.properties.latitude)
          .labelLng((d) => d.properties.longitude)
          .labelText((d) => d.properties.name)
          .labelSize(
            (d) => Math.sqrt(d.properties.pop_max) * 4e-4,
          )
          .labelDotRadius(
            (d) => Math.sqrt(d.properties.pop_max) * 4e-4,
          )
          .labelColor(() => 'rgba(255, 165, 0, 0.75)')
          .labelResolution(2)(
          document.getElementById('globeViz'),
        );
      });
  },
};
