
// import HeaderOnePage from "../../layout/components/header.vue";
import Banner from '../components/banner.vue';
import Service from '../../service/components/service';
import About from '../../about/components/about';

import Portfolio from '../../portfolio/components/portfolio';
import Counter from '../../counter/components/counter';
//   import Team from "../../components/team/Team";
import Testimonial from '../../testimonial/components/testimonial';
import Blog from '../../blog/components/blog';
import Contact from '../../contact/components/contact';
import Brand from '../../brand/components/brand';
import Footer from '../../footer/components/footer';
import Globe from '../../globe/components/globe';
// import axios from 'axios';

export default {
  components: {
    // HeaderOnePage,
    Banner,
    About,
    Service,
    Portfolio,
    Counter,
    //   Team,
    Testimonial,
    Blog,
    Brand,
    Contact,
    Footer,
    Globe,
  },
  data() {
    return {
      fileName: 'earth-styling.htm',
      input: '',
      // logo: require("../../../assets/logo.svg"),
      // logoLight: require("../../../assets/logoLight.svg"),
      // html
    };
  },
  created() {
    this.fileName = this.$route.params.fileName;
    // this.loadFile()
  },
  computed: {
    compiledHtml: function () {
      return this.input;
    },
  },
  methods: {
    // loadFile() {
    //     axios({
    //       method: "get",
    //       url: "../animations.htm"
    //     })
    //       .then(result => {
    //         this.input = result.data;
    //       })
    //       .catch(error => {
    //         console.error("error getting file: ", error);
    //       });
    //   }
  },
};
