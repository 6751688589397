
// import VueSlickCarousel from 'vue-slick-carousel';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    // VueSlickCarousel,
  },
  async created() {
    await this.doFetchHomePageBlog();
  },
  data() {
    return {
      // for all works
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        spaceBetween: 15,

        responsive: [
          {
            breakpoint: 890,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 490,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      blog: 'blog/blog',
    }),
  },
  methods: {
    ...mapActions({
      doFetchHomePageBlog: 'blog/doFetchHomePageBlog',
    }),
  },
};
