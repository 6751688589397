
export default {
  data() {
    return {
      brandImagesDark: [
        {
          src: require('../../../assets/images/brand/google.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/shopify.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/magento.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/brand.png'),
          url: '#',
        },

        {
          src: require('../../../assets/images/brand/vue.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/odoo.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/wordpress.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/black.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/figma.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/Node.js.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/flutter-dark.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/kotlin.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/swift-dark.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/firebase-dark.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/unreal-dark.png'),
          url: '#',
        },
      ],
      brandImagesLight: [
        {
          src: require('../../../assets/images/brand/google.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/shopify.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/magento.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/brand.png'),
          url: '#',
        },

        {
          src: require('../../../assets/images/brand/vue.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/odoo.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/wordpress-light.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/black.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/figma.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/Node.js.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/flutter.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/kotlin.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/swift.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/firebase.png'),
          url: '#',
        },
        {
          src: require('../../../assets/images/brand/unreal-light.png'),
          url: '#',
        },
      ],
    };
  },
};
