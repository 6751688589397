
  import VisibilitySensor from "vue-visibility-sensor";
  import countTo from "vue-count-to";
  import feather from "feather-icons";
  export default {
    components: {
      countTo,
      VisibilitySensor,
    },
    data() {
      return {
        countUp: true,
        status: false,
        counterUpContent: [
          {
            icon: "heart",
            endVal: 25,
            desc: `Statisfied Customers`,
          },
          {
            icon: "clock",
            endVal: 1080,
            desc: `Days Of Operation`,
          },
          {
            icon: "check-circle",
            endVal: 30,
            desc: `Completed Projects`,
          },
        ],
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        }
      },
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
