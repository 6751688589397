
  export default {
    data() {
      return {
        tab: null,
        testimonialContent: [
          {
            id: 1,
            content: [
              {
                name: "Dr. Waleed Arafat",
                description: "Runprof was quite helpful in helping us design and construct a website. They went above and beyond our expectations, creating a beautiful website that we would suggest to others.",
                designation: "Chairman of Smouha SC",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                name: "Ahmed Medhat",
                description:
                  "Runprof considered a perfect choice ' they eat ,sleep and breathe code to transform how the world builds software’",
                designation: "CEO of Drug Deal",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                name: "Dr. Maha El Mazroo",
                description: "ASAS system made our daily use easier than just papers to a complete system",
                designation: "Hospital Manager of Regional Poison Control Center",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                name: "Moataz Ragab ",
                description:
                  "As Co-owner and vice president of Israa Group, I was very pleased to deal with Runprof company. We have got cost effective and high quality website. Their team was so friendly and listening to every single detail of our sophisticated work agenda. Luckily, they exceeded our expectations and suprised us with a beautiful website. They are highly recommended to whomever interested in web development for his establishment.",
                designation: "Vice President of Israa Group",
              },
            ],
          },
          {
            id: 5,
            content: [
              {
                name: "Captain Ahmed Hassan",
                description:
                  "Runprof's team was really pleasant and attentive to every element of our project. Anyone interested in web development should strongly consider these.",
                designation: "Former Member of Board of Directors",
              },
            ],
          },
          {
            id: 7,
            content: [
              {
                name: "Hossam El-Ghamdy",
                description:
                  "The team at Runprof company was very friendly and listened to every detail of our work. They are highly recommended to anyone who is interested in web development.",
                designation: "COO of UsedBrands",
              },
            ],
          },
          {
            id: 8,
            content: [
              {
                name: "Dr. El-Sayed Faisal",
                description:
                  "Runprof company was very helpful in designing and developing a website for us. They exceeded our expectations and provided us with a great looking website that is highly recommended to others.",
                designation: "Chairman of Al Faisal International Group",
              },
            ],
          },
          {
            id: 9,
            content: [
              {
                name: "Farag Amer",
                description:
                  "The Runprof staff was quite pleasant to work with and was extremely attentive to every detail of our project. These should be seriously considered by everyone interested in web development.",
                designation: "Chairman of Smouha Al Alamein",
              },
            ],
          },
        ],

        tabItems: [
          {
            id: 1,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FSmouha.png?alt=media&token=88032370-3616-4ca2-8611-442f6c15d4b7",
          },
          {
            id: 2,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FDrug%20Deal.png?alt=media&token=b79adb54-9c95-425c-88f6-81f75dfcec68",
          },
          {
            id: 3,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FMinistry%20of%20Health.png?alt=media&token=839a4824-a454-421e-a317-f210928ee227",
          },
          {
            id: 5,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FIsraa.png?alt=media&token=04508253-17d6-4e55-9aa2-077e7387ab41",
          },
          {
            id: 6,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FPyramids.png?alt=media&token=1204bcc1-2ade-41f9-83f6-2750fcbeaf53",
          },
          {
            id: 7,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FUsed%20Brands.png?alt=media&token=06297c60-7631-426a-b4e3-1f1dced32dea",
          },
          {
            id: 8,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FPuricom.png?alt=media&token=826c0ec5-4968-45a0-a718-e71b539bf2e4",
          },
          {
            id: 9,
            src: "https://firebasestorage.googleapis.com/v0/b/runprof-c02dd.appspot.com/o/testimonials%2FSmouha%20Alamein.png?alt=media&token=c91a5769-ed4f-484c-aec3-ddbfdb3aa80d",
          },
        ],
      };
    },
  };
